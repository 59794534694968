import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { isIOS } from "react-device-detect"
import { Helmet } from "react-helmet"
import ReactHtmlParser from "react-html-parser"
import TruncateMarkup from "react-truncate-markup"
import Layout from "../components/layout"
import Seo from "../components/seo"

const JobIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const jobs = data.allWpJob.nodes
  const pageContent = data.allWpPage.nodes
  const postsPerPage = 3
  let arrayForHoldingPosts = []


  const [uniqLocs, setUniqLocs] = useState(data.allWpJobLocation?.nodes)
  const [uniqJobs, setUniqJobs] = useState(data.allWpJobTitle?.nodes)


  const [postsToShow, setPostsToShow] = useState(jobs)
  const [filtered, setFiltered] = useState(jobs)

  const [next, setNext] = useState(3)

  const [hideLoad, setHideLoad] = useState(false)

  // job dropdown

  const [dropTitle, setDropTitle] = useState("")
  const [dropLocation, setDropLocation] = useState("")

  const loopWithSlice = (start, end) => {
    const slicedPosts = filtered ? filtered.slice(0, end) : jobs.slice(0, end)
    arrayForHoldingPosts.concat(slicedPosts)
    setPostsToShow(slicedPosts)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
    if (postsToShow.length >= jobs.length - 3) {
      setHideLoad(true)
    }
  }, [])

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
    setHideLoad(true)

    if (postsToShow.length >= jobs.length - 3) {
      setHideLoad(false)
    }
  }

  const onDropLoc = e => {
    setDropLocation(e.target.value)
  }

  const onDropTitle = e => {
    setDropTitle(e.target.value)
  }

  const onJobFilter = () => {
    if (dropTitle && !dropLocation) {
      if (dropTitle == "all") {
        setPostsToShow(jobs)
      } else {
        setPostsToShow(jobs.filter(post => post.jobs.title.name == dropTitle))
      }
    } else if (dropLocation && !dropTitle) {
      if (dropLocation == "all") {
        setPostsToShow(jobs)
      } else {
        setPostsToShow(
          jobs.filter(post => post.jobs.location.name == dropLocation)
        )
      }
    } else if (dropTitle && dropLocation) {
      if (dropLocation == "all" && dropTitle !== "all") {
        setPostsToShow(jobs.filter(post => post.jobs.title.name == dropTitle))
      } else if (dropTitle == "all" && dropLocation !== "all") {
        setPostsToShow(
          jobs.filter(post => post.jobs.location.name == dropLocation)
        )
      } else if (dropTitle == "all" && dropLocation == "all") {
        setPostsToShow(jobs)
      } else {
        setPostsToShow(
          jobs.filter(
            post =>
              post.jobs.title.name == dropTitle &&
              post.jobs.location.name == dropLocation
          )
        )
      }
    } else {
      setPostsToShow(jobs)
    }
    setFiltered(postsToShow)
    if (next <= filtered.length + 1 && postsToShow.length <= jobs.length) {
      setHideLoad(false)
    }
  }

  console.log(data.allWpJobTitle?.nodes)
  return (
    <div>
      <Seo
        title={
          pageContent.seo?.title
            ? pageContent.seo.title
            : "Jobs in Medical Aesthetics"
        }
        description={pageContent.seo?.metaDesc}
      />
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0"
        />
      </Helmet>

      <Layout>
        <section className="header_section">
          <div className="container">
            <div className="header_section__content">
              <h1>Jobs</h1>
              <div className="header_section__content_sub">
                <div>The Expert Recruiters in Medical Aesthetics </div>
              </div>
              <div className="header_section__content_btn text-center">
                <a href="#content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="bounce"
                    width="109.491"
                    height="57.574"
                    viewBox="0 0 109.491 57.574"
                  >
                    <path
                      id="Path_5"
                      data-name="Path 5"
                      d="M-847.3,2972.583h0l53.331,53.331-53.331,53.332"
                      transform="translate(3080.66 848.711) rotate(90)"
                      fill="none"
                      stroke="rgba(53,51,67,0.5)"
                      strokeWidth="4"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="header_section__image">
              <div className="header-container--standars">
                <StaticImage
                  src="../../static/assets/images/header/image_1.svg"
                  placeholder="blurred"
                  alt="Arc aesthetics"
                />
              </div>
            </div>
          </div>
          <div className="standard_header_path">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 1924.003 706.339"
            >
              <path
                id="Path_1"
                data-name="Path 1"
                d="M.5,1210.979h1924V504.64S1271.73,915.921,929.1,801.894.5,1037.8.5,1037.8Z"
                transform="translate(-0.5 -504.64)"
                fill="#ecebe4"
              />
            </svg>
          </div>
        </section>

        <section className="intro_text">
          <div className="container" id="content">
            <div className="section__title text-center">Jobs in Aesthetics</div>

            <div className="intro_text__holder">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageContent[0].content,
                }}
              />
            </div>
          </div>
        </section>

        <section className={`job_seach ${isIOS ? "job_seach--default" : ""} `}>
          <div className="container">
            <div className="job_seach__form">
              <div className="job_seach__form_input input-field">
                <select
                  className={isIOS ? "browser-default" : ""}
                  onChange={e => onDropTitle(e)}
                  id="titleDrop"
                >
                  <option defaultValue disabled>
                    JOB TITLE
                  </option>

                  <option value="all">All Job Titles</option>

                  {uniqJobs
                    .filter(a => a.count)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((title, index) => {
                      return (
                        <option key={index} value={title.name}>
                          {title.name}
                        </option>
                      )
                    })}
                </select>
              </div>

              <div className="job_seach__form_input input-field">
                <select
                  className={isIOS ? "browser-default" : ""}
                  onChange={e => onDropLoc(e)}
                  id="location"
                >
                  <option disabled defaultValue>
                    LOCATION
                  </option>

                  <option value="all">All Job locations</option>

                  {uniqLocs
                    .filter(a => a.count)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((loc, index) => {
                      return (
                        <option key={index} value={loc.name}>
                          {loc.name}
                        </option>
                      )
                    })}
                </select>
              </div>

              <div className="job_seach__form_btn">
                <a className="btn" onClick={onJobFilter}>
                  Search
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="job_listings">
          <div className="container">
            <div className="job_listings__holder">
              {!postsToShow.length && (
                <h4 style={{ paddingTop: 70, color: "black" }}>
                  No posts found
                </h4>
              )}

              {postsToShow.map((job, i) => {
                const title = job.title

                return (
                  <div className="job_listings__card card" key={i}>
                    <div className="services__card_title">{title}</div>
                    <div
                      className="services__card_id"
                      style={{ marginBottom: 10 + "px" }}
                    >
                      {job.jobs.jobId}
                    </div>

                    <div className="services__card_i">
                      <img
                        width="30"
                        src="/assets/images/icons/location.svg"
                        placeholder="blurred"
                        alt="Location"
                        style={{ marginRight: 10 + "px" }}
                      />
                      {job.jobs.location.name}
                    </div>
                    <div className="services__card_i">
                      <img
                        width="30"
                        src="/assets/images/icons/money.svg"
                        alt="Salary"
                        style={{ marginRight: 10 + "px" }}
                      />

                      {job.jobs.salary}
                    </div>

                    <div className="services__card_desc">
                      <TruncateMarkup
                        lines={4}
                        lineHeight={30}
                        ellipsis={"..."}
                      >
                        <div>{ReactHtmlParser(job.content)}</div>
                      </TruncateMarkup>
                    </div>
                    <div className="services__card_btn">
                      <a href={`/jobs/${job.slug}`} className="btn">
                        READ MORE
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="job_listings__more">
              {hideLoad && (
                <a onClick={handleShowMorePosts} className="btn">
                  LOAD MORE
                </a>
              )}
            </div>
          </div>
        </section>
      </Layout>
      <Helmet>
        <script
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/19906557.js"
        />
      </Helmet>
      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </div>
  )
}

export default JobIndex

export const pageQuery = graphql`
  query JobPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpJob(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        slug
        title
        content
        jobs {
          title {
            id
            name
            slug
          }
          salary
          location {
            id
            name
            slug
          }
          jobId
          jobType
        }
      }
    }
    allWpPage(filter: { uri: { eq: "/jobs/" } }) {
      nodes {
        uri
        slug
        title
        content
      }
    }

    allWpJobLocation {
      nodes {
        name
        count
        slug
        nodeType
      }
    }

    allWpJobTitle {
      nodes {
        name
        count
        slug
        nodeType
      }
    }
  }
`
